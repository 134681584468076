<script lang="ts" setup>
const authStore = useAuthStore();
</script>

<template>
  <CoreSessionTimeoutModal />
  <div>
    <div v-if="authStore.isloggingOut">
      <div />
    </div>
    <div v-else>
      <slot />
    </div>
  </div>
</template>
